<template>
  <b-overlay
    :show="overlayLoadDataTable"
    rounded
    opacity="0.6"
    spinner-variant="primary"
  >
    <b-table-simple
      small
      caption-top
      responsive
      class="position-relative"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            v-for="(item, index) in tableTitle"
            :key="index"
            :class="item.class"
            :style="item.style"
          >
            {{ item.title }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="totalData">
          <slot name="data" />
        </template>
        <template v-else>
          <b-tr>
            <b-td
              :colspan="tableTitle.length"
              class="text-center"
            >
              ไม่พบรายการที่ตรงกัน
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th
            v-for="(item, index) in tableTitle"
            :key="index"
            :class="item.class"
            :style="item.style"
          >
            {{ item.title }}
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BTableSimple, BThead, BTr, BTh, BTbody, BTfoot, BTd,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTfoot,
    BTd,
  },

  props: {
    overlayLoadDataTable: {
      type: Boolean,
      required: true,
    },

    tableTitle: {
      type: Array,
      required: true,
    },

    totalData: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.table thead th, .table tfoot th {
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    height: 40px;
}

.table tbody td {
    vertical-align: middle;
    letter-spacing: 0.5px;
    height: 40px;
}
</style>
