<template>
  <div>
    <b-card no-body>
      <input-search :search.sync="searchQuery" />
      <pagination
        :item-local-to="itemLocalTo"
        :item-local-count="itemLocalCount"
        :total-data="totalData"
        :current-page.sync="currentPage"
        :per-page="perPage"
        show-detail
      />
      <table-simple
        :overlay-load-data-table="overlayLoadDataTable"
        :table-title="tableTitle"
        :total-data="dataTable.length"
      >
        <template slot="data">
          <b-tr
            v-for="(item, index) in dataTable"
            :key="index"
          >
            <b-td>
              {{ item.order }}
            </b-td>
            <b-td>
              {{ item.membertype_desc }}
            </b-td>
            <b-td>
              {{ item.membertype_desc_en }}
            </b-td>
            <b-td>
              <b-badge
                :variant="`${item.total_permission ? 'primary' : 'light-secondary'}`"
              >
                {{ Number(item.total_permission).toLocaleString() }}
              </b-badge>
            </b-td>
            <b-td>
              <b-badge
                :variant="`${item.total_member ? 'primary' : 'light-secondary'}`"
              >
                {{ Number(item.total_member).toLocaleString() }}
              </b-badge>
            </b-td>
            <b-td>
              <b-link :to="{path:`permission/form/${item.member_type_id}`}">
                <feather-icon
                  icon="FileTextIcon"
                  size="16"
                  class="align-middle text-primary"
                />
                กำหนดสิทธิ
              </b-link>
            </b-td>
          </b-tr>
        </template>
      </table-simple>
      <pagination
        :item-local-to="itemLocalTo"
        :item-local-count="itemLocalCount"
        :total-data="totalData"
        :current-page.sync="currentPage"
        :per-page="perPage"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTr, BTd, BLink, BBadge,
} from 'bootstrap-vue'
import InputSearch from '../components/InputSearch.vue'
import TableSimple from '../components/TableSimple.vue'

import usePermission from './usePermission'
import Pagination from '../components/Pagination.vue'

export default {
  components: {
    BCard,
    BTr,
    BTd,
    BLink,
    BBadge,

    InputSearch,
    TableSimple,
    Pagination,
  },

  setup() {
    // variable from import
    const {
      // variable table
      currentPage,
      totalData,
      perPage,
      tableTitle,
      dataTable,
      searchQuery,
      itemLocalTo,
      itemLocalCount,

      // variable overlay
      overlayLoadDataTable,
    } = usePermission()

    return {
      // variable table
      currentPage,
      totalData,
      perPage,
      tableTitle,
      dataTable,
      searchQuery,
      itemLocalTo,
      itemLocalCount,

      // variable overlay
      overlayLoadDataTable,
    }
  },
}
</script>

<style scoped>
.table tbody td {
    vertical-align: middle;
    letter-spacing: 0.5px;
    height: 40px;
}
</style>
