<template>
  <div class="mx-2 mb-1">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <template v-if="showDetail">
          <span class="text-muted">{{ itemLocalTo }} - {{ itemLocalCount }}   ทั้งหมด {{ totalData }} รายการ</span>
        </template>
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="reCurrentPage"
          :total-rows="totalData"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BPagination,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
  },

  props: {
    itemLocalTo: {
      type: Number,
      required: true,
    },

    itemLocalCount: {
      type: Number,
      required: true,
    },

    totalData: {
      type: Number,
      required: true,
    },

    currentPage: {
      type: Number,
      required: true,
    },

    perPage: {
      type: Number,
      required: true,
    },

    showDetail: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const reCurrentPage = computed({
      get() {
        return props.currentPage
      },
      set(val) {
        emit('update:current-page', val)
      },
    })

    return {
      reCurrentPage,
    }
  },
}
</script>

<style>

</style>
