<template>
  <div class="m-2 mb-1">
    <b-input-group>
      <b-form-input
        v-model="inputSearch"
        placeholder="ค้นหาข้อมูล..."
        @keydown.enter.native="$emit('update:search', inputSearch)"
      />
      <b-input-group-append>
        <b-button
          variant="primary"
          style="height: 38px;"
          @click="$emit('update:search', inputSearch)"
        >
          <feather-icon icon="SearchIcon" />
          <span
            v-if="$store.state.app.windowWidth >= 800"
            class="align-middle"
          >
            ค้นหา
          </span>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import {
  BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },

  setup() {
    // variable input
    const inputSearch = ref(null)

    return {
      // variable input
      inputSearch,
    }
  },
}
</script>

<style>

</style>
